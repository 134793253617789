
import { Options, Vue } from 'vue-class-component';
import { AdminClient } from '@/services/Services';
import EditPropertyModal from '../modals/editPropertyModal.vue';
import * as VM from '@/viewModel';

@Options({
    components: {}
})
export default class EmployeeEquipmentData extends Vue {

    nameValue: string = "";
    oldValues: VM.ListStringAsObj[] = [];

    created() {
        this.init();
    }

    init() {
        AdminClient.getAllEmployEquipmentPropertyNames()
        .then(x => {
            this.oldValues = x.map(y => new VM.ListStringAsObj(y));
        })
    }

    get saveDisabled() {
        return !this.nameValue;
    }

    addProperty() {
        AdminClient.addEmployEquipmentProperty(this.nameValue)
        .then(x => {
            this.nameValue = "";
            this.init();
        })
    }

    editProperty(oldValue: string) {
        this.$opModal.show(EditPropertyModal, {
            propertyName: oldValue,
            callback: (newValue: string) => {
                AdminClient.updateEmployEquipmentPropertyName(oldValue, newValue)
                .then(x => {
                    this.$opModal.closeLast();
                    this.init();
                })
            }
        })
    }

}
