
import { Options, Vue } from 'vue-class-component';
import EmployeePersonalData from './components/employeePersonalData.vue';
import EmployeeSecurityData from './components/employeeSecurityData.vue';
import EmployeeEquipmentData from './components/employeeEquipmentData.vue';

@Options({
    components: {
        EmployeePersonalData,
        EmployeeSecurityData,
        EmployeeEquipmentData
    }
})
export default class EmployeesConfiguration extends Vue {

    activeSection: number = 1;

    created() {
    }

}
