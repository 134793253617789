import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/img/arrow.left.svg'


const _hoisted_1 = { class: "page_header" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "foldersTabContainer" }
const _hoisted_4 = { class: "formContainer withFolders" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_localized_text = _resolveComponent("localized-text")!
  const _component_employee_personal_data = _resolveComponent("employee-personal-data")!
  const _component_employee_security_data = _resolveComponent("employee-security-data")!
  const _component_employee_equipment_data = _resolveComponent("employee-equipment-data")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("img", {
          src: _imports_0,
          class: "pointer me-3",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.back()))
        }),
        _createVNode(_component_localized_text, {
          type: 'h1',
          localizedKey: "Configurazione dipendente",
          text: "Configurazione dipendente",
          class: "mb-0"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: _normalizeClass(["tab", {'active' : _ctx.activeSection == 1}]),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.activeSection = 1))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Informazioni personali",
          text: "Informazioni personali"
        })
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["tab", {'active' : _ctx.activeSection == 2}]),
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.activeSection = 2))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Sicurezza",
          text: "Sicurezza"
        })
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["tab", {'active' : _ctx.activeSection == 3}]),
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.activeSection = 3))
      }, [
        _createVNode(_component_localized_text, {
          localizedKey: "Attrezzatura",
          text: "Attrezzatura"
        })
      ], 2)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.activeSection == 1)
        ? (_openBlock(), _createBlock(_component_employee_personal_data, { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.activeSection == 2)
        ? (_openBlock(), _createBlock(_component_employee_security_data, { key: 1 }))
        : _createCommentVNode("", true),
      (_ctx.activeSection == 3)
        ? (_openBlock(), _createBlock(_component_employee_equipment_data, { key: 2 }))
        : _createCommentVNode("", true)
    ])
  ], 64))
}